import * as Redux from 'redux'
import * as Actions from './actions'
import * as Constants from './constants'
import * as Types from './types'

type Action = Actions.UpdateFieldAction & Actions.ModalAction

const createModalReducer = (scope: string, key: string) =>
  (state: Types.ModalStateData, action: Action): Types.ModalStateData => {
  switch (action.type) {
    case Constants.resetModalConstant(scope, key):
      return {
        ...state,
        data: {},
      }
    case Constants.closeModalConstant(scope, key):
      return {
        ...state,
        isOpen: false,
      }
    case Constants.saveModalConstant(scope, key):
      return {
        ...state,
        isOpen: false,
        isSaved: true,
      }
    case Constants.openModalConstant(scope, key):
      if (state.maxTimesToShow && state.numTimesShowed >= state.maxTimesToShow) {
        return state
      } else {
        return {
          ...state,
          data: action.data || {},
          isDirty: false,
          isOpen: true,
          isSaved: false,
          maxTimesToShow: action.maxTimesToShow,
          numTimesShowed: (state.numTimesShowed + 1),
        }
      }
    case Constants.updateModalConstant(scope, key):
      return {
        ...state,
        isDirty: true,
        data: {
          ...state.data,
          [action.field.name]: action.field.value,
        },
      }
    case Constants.setSessionModalConstant(scope, key):
      return {
        ...state,
        data: {
          ...state.data,
          ...action.data,
        },
      }
    default:
      return state || Constants.emptyState
    }
}

export const createReducer = (dataReducer: any, scope: string, modalKeys: string[]) => {
  return Redux.combineReducers({
    data: dataReducer,
    modals: Redux.combineReducers(modalKeys.reduce((results, modalKey: string) => ({
      ...results,
      [modalKey]: createModalReducer(scope, modalKey),
    }), {})),
  })
}
