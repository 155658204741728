export enum TemplateModuleType {
  header = 'header',
  leadText = 'leadText',
  image = 'image',
  article = 'article',
  twoColumnImage = 'twoColumnImage',
  restOfArticles = 'restOfArticles',
  footer = 'footer',
  section = 'section',
  sponsored = 'sponsored',
  event = 'event',
}

export enum SectionConfig {
  SECTION_FONT_COLOR = 'sectionFontColor',
  SECTION_FONT_SIZE = 'sectionFontSize',
  SECTION_FONT_WEIGHT = 'sectionFontWeight',
  SECTION_SHOW_HEADER = 'sectionShowHeader',
  SECTION_BORDER_COLOR = 'sectionBorderColor',
  SECTION_BORDER_SIZE = 'sectionBorderSize',
}

export interface ModuleConfig {
  count?: number,
  end?: number,
  start?: number,
  isScheduled?: boolean,
  sectionId?: number,
  readMore?: string,
  [SectionConfig.SECTION_FONT_COLOR]?: string,
  [SectionConfig.SECTION_FONT_SIZE]?: string,
  [SectionConfig.SECTION_FONT_WEIGHT]?: string,
  [SectionConfig.SECTION_SHOW_HEADER]?: string,
  [SectionConfig.SECTION_BORDER_COLOR]?: string,
  [SectionConfig.SECTION_BORDER_SIZE]?: string,
}

export interface TemplateModule {
  hidden: boolean,
  type: TemplateModuleType,
  isFirstArticleModule?: boolean,
  isManuallyAdded?: boolean,
  moduleConfig: ModuleConfig,
  sequence: number,
}
