import {ProgressBar} from '@progress/kendo-react-progressbars'
import classnames from 'classnames'
import React, {Component} from 'react'
import {Button} from 'reactstrap'
import {UpgradeButton} from '../../components/upgrade/button'
import {UPGRADE_BUTTON_COLOR} from '../../components/upgrade/constants'
import './styles.scss'
import { isEmpty } from 'lodash'
import {SUPPORT_EMAIL, SUPPORT_MAIL_TO} from '../../constants';

export interface UsageMeterComponentProps {
  actionButton?: {
    callback: any
    disabled: boolean
    label: string
  }
  entity?: string
  isAdmin?: boolean
  isUnlimited: boolean
  isLimitReached: boolean
  showContactToRasa?: boolean
  currentCount: number
  maxLimit: number
  source: string
  progressText: string
  progressDescription: string
}

export class UsageMeterComponent<T extends UsageMeterComponentProps, S extends object>
  extends Component<T, S> {
  constructor(props: T) {
    super(props)
  }
  public render() {
    return <div className="usage-meter-container">
      <div className="section-container">
        {!this.props.isAdmin && !this.props.isUnlimited && this.renderProgressBar()}
        {
          !this.props.isAdmin && !this.props.isUnlimited && this.props.isLimitReached ?
            !this.props.showContactToRasa && this.renderUpdateButton() :
            !isEmpty(this.props.actionButton) && this.renderActionButton()
        }
      </div>
      {
        !this.props.isAdmin && !this.props.isUnlimited && this.props.isLimitReached && <>
          {this.props.showContactToRasa && this.renderContactToRasa()}
          {!this.props.showContactToRasa && this.renderMeterDescription()}
        </>
      }
    </div>
  }

  private renderContactToRasa() {
    return <div className="max-limit-description">
        You have reached your limit. <br/>
        Please contact <a href={SUPPORT_MAIL_TO}>{SUPPORT_EMAIL}</a> <br/>
        to discuss adding more {this.props.entity ? this.props.entity : ''}
      </div>
  }

  private renderMeterDescription() {
    return <div className="max-limit-description">
        {
          this.props.progressDescription ?
            this.props.progressDescription :
            'You\'ve reached your users limit, please upgrade your plan.'
        }
      </div>
  }

  private renderActionButton() {
    return <div className={classnames('add-sections-button-text-section')}>
      <Button
        className={classnames('add-sections-button', `${this.props.actionButton.disabled && 'disabled'}`)}
        onClick={() => this.props.actionButton.callback()}>
        <span className="sections-button-text">
          {this.props.actionButton.label}
        </span>
      </Button>
    </div>
  }

  private renderUpdateButton() {
    return <div>
        <UpgradeButton color={UPGRADE_BUTTON_COLOR.WHITE} showIcon={true}
                       source={this.props.source} text="Upgrade"/>
      </div>
  }

  private renderProgressBar() {
    return <div className="div-progress-bar">
        <ProgressBar
          min={0}
          className={`progress-bar${this.props.isLimitReached ? '-red' : ''}`}
          max={this.props.maxLimit}
          value={this.props.currentCount}
          labelVisible={false}/>
        <div className="progress-bar-label">
          <div>{this.props.progressText}</div>
          <div>{this.props.currentCount}/{this.props.maxLimit}</div>
        </div>
      </div>
  }
}
