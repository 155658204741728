import { SELECTED_COMMUNITY } from 'store'
import { writeLog } from 'utils'
import { SESSION_STORAGE_KEY } from './constants'

export enum StatsType {
  LOAD_TIME = 'LOAD_TIME',
  MENU_LOAD = 'MENU_LOAD',
  MENU_RELOAD = 'MENU_RELOAD',
  USER_INIT = 'USER_INIT',
}

export class Stats {
  public static init(): Stats {
    if (!this.instance) {
      Stats.instance = new Stats()
    }
    return Stats.instance
  }

  private static instance = null
  private state: object
  private finished: object

  private constructor() {
    this.state = {}
    this.finished = {}
  }

  public start(stat) {
    this.state[stat] = performance.now()
  }

  public stop(stat) {
    if (this.state[stat]) {
      this.finished[stat] = Math.round(performance.now() - this.state[stat])
      delete this.state[stat]
    }
  }

  public getStat(stat) {
    return this.finished[stat]
  }

  public setStat(stat, value) {
    return this.finished[stat] = Math.round(value)
  }

  public stopAndLog(stat, type: string = null) {
    this.stop(stat)
    this.log(stat, type)
  }

  public stopAll() {
    Object.keys(this.state).forEach((key) => this.stop(key))
  }

  public log(stat, type: string = null) {
    if (this.finished[stat]) {
      const statsInfo = {
        [stat] : this.finished[stat],
        community: localStorage.getItem(SELECTED_COMMUNITY) || '',
        session: sessionStorage.getItem(SESSION_STORAGE_KEY) || '',
      }
      if (type) {
        // eslint-disable-next-line @typescript-eslint/dot-notation
        statsInfo['type'] = type
      }
      // eslint-disable-next-line
      writeLog(statsInfo)
    }
  }
}
