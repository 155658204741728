import { Dataset, DatasetParam } from 'generic/dataset'

export interface Category {
  id: number,
  name: string,
  rank: number,
  source_count: number
}

export const getCategories = (minRank: number): Promise<Category[]> => {
  const params: DatasetParam[] = [
    {
      param: 'rank',
      value: minRank
    }
  ]
  return new Dataset().loadGlobalDataset('categories', params)
    .then((response: any) => response[0])
}

export interface SubCategory extends Category {
  parent: Category
}

export const getSubCategories = (minRank: number): Promise<SubCategory[]> => {
  const params: DatasetParam[] = [
    {
      param: 'rank',
      value: minRank
    }
  ]
  return new Dataset().loadGlobalDataset('subCategoriesMapping', params)
    .then((response: any) => response[0])
    .then((data: any[]) => {
      return data.map((item: any) => ({
        id: item.sub_category_id,
        name: item.sub_category_name,
        rank: item.sub_category_rank,
        source_count: item.source_count,
        parent: {
          id: item.category_id,
          name: item.category_name,
          rank: item.category_rank,
          source_count: 0,
        }
      }))
    })
}