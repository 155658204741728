// Functionality to run when we first load up the application
// Auth needs to be wired in here
import * as AuthConstants from 'auth/constants'
import * as BillingUtils from 'billing/utils'
import { DashboardMenuOption } from 'components/dashboard-menu/component'
import { isAfter, parseISO } from 'date-fns'
import { isEmpty, isNil } from 'lodash'
import { CommunitySystem } from 'shared/data-layer/integration'
import { getCommunityNextIssue } from 'shared/data-layer/issues'
import {
  BillingPlanDetailCode,
  CommunityBillingInfo,
  getPlanDetailLimitValue,
} from 'shared_server_client/types/billing_plan'
import { UpcomingIssue } from 'shared_server_client/types/schedule'
import { BaseClientEntity } from '../generic/baseClientEntity'
import { Dataset } from '../generic/dataset'
import { EntityMetadataClient } from '../generic/entityMetadataClient'
import * as Utils from '../generic/utility'
import { Stats, StatsType } from '../stats'
import { AttributeStore, SELECTED_COMMUNITY } from '../store'
import { DISCONNECTED, NO_COMMUNITY } from './constants'
import { DomainStatus } from 'shared_server_client/constants'

export interface AccountAndUserInfo {
  account_id: number,
  address_1: string,
  address_2: string,
  billing_system_identifier: string,
  city: string,
  company_name: string,
  country: string,
  created: string,
  email: string,
  expiration_date: string,
  facebook_handle: string,
  first_name: string,
  is_2fa_required: number,
  is_active: number,
  is_cancelled: number,
  last_login: string,
  last_name: string,
  last_pending_verification: string,
  linkedin_handle: string,
  post_code: string,
  twitter_handle: string,
  updated: string,
  verification_count: number,
  website: string,
}

export interface AnnouncementInfo {
  description: string,
  id: number,
  is_preview: boolean,
  title: string,
  type: string,
}

export class UserCommunityInfo {

  public readonly communityId: string
  public readonly communityName: string
  public readonly communityDisplayName: string
  public readonly isActive: boolean
  public readonly productSubscriptionId: number
  public readonly subscriptionAccountId: number
  public readonly accountSource: string
  public readonly role: string
  public readonly roleId: number
  public readonly communityAccountId: string
  public readonly data: any
  public nextIssue: UpcomingIssue
  public billingInfo: CommunityBillingInfo
  public lastBillingDate: string
  public nextBillingDate: string
  public expirationDate: string
  public disconnectedCommunitySystems: CommunitySystem[]
  public failedCommunityIntegrations: CommunitySystem[]

  private _communityInfo: BaseClientEntity

  constructor(data: any) {
    this.communityId = data.community_identifier
    this.productSubscriptionId = data.product_subscription_id
    this.communityName = data.community_name
    this.communityDisplayName = data.community_display_name
    this.isActive = data.is_active
    this.role = data.role
    this.roleId = data.primary_role_id
    this.communityAccountId = data.community_account_id
    this.expirationDate = data.expiration_date
    this.subscriptionAccountId = data.subscription_account_id
    this.accountSource = data.account_source

    this.data = data
  }

  public set communityInfo(communityInfo: BaseClientEntity) {
    this._communityInfo = communityInfo;
  }
  public get communityInfo(): BaseClientEntity {
    return this._communityInfo
  }
}

export class PersonInfo {

  public readonly id: number
  public readonly accountId: number
  public readonly userId: number
  public readonly fullName: string
  public readonly firstName: string
  public readonly lastName: string
  public readonly email: string
  public readonly guid: string
  public billingInfo: CommunityBillingInfo
  public isInvalidDomain: boolean
  public connectedCommunitySystems: CommunitySystem[]
  private _accountInfo: AccountAndUserInfo

  constructor(data: any) {
    this.id = data.person_id
    this.accountId = data.account_id
    this.userId = data.user_id
    this.fullName = data.full_name
    this.firstName = data.first_name
    this.lastName = data.last_name
    this.email = data.email
    this.guid = data.person_guid
  }

  public set accountInfo(accountInfo: AccountAndUserInfo) {
    this._accountInfo = accountInfo;
  }
  public get accountInfo(): AccountAndUserInfo {
    return this._accountInfo
  }

  public get hasMultipleNewsletterAccess(): boolean {
    const maxNewslettersAllowed = getPlanDetailLimitValue(
      this.billingInfo.currentPlan,
      BillingPlanDetailCode.MAX_NEWSLETTERS)
    return maxNewslettersAllowed !== 1
  }

  public get verified(): boolean {
    if (this._accountInfo){
      return this._accountInfo.verification_count > 0
    }
    return false
  }

  public get lastPendingVerificationSent(): string {
    return this._accountInfo.last_pending_verification
  }

  public accountExpired(): boolean {
    if (!!this._accountInfo && this._accountInfo.expiration_date) {
      const expired = new Date(parseISO(this._accountInfo.expiration_date))
      return isAfter(new Date(), expired)
    } else {
      return false
    }
  }
}

export type UserInitCallback =
  (person: PersonInfo, community: UserCommunityInfo, communities: UserCommunityInfo[]) => void

export class User {
  private static _getUserCommunitiesPromise: Promise<any>
  private _token: string
  private _person: PersonInfo;
  private _activeCommunity: UserCommunityInfo;
  private _entityMetadata: EntityMetadataClient;
  private _store: AttributeStore
  private _stats: Stats
  private _announcements: AnnouncementInfo[]

  constructor(entityMetadata: EntityMetadataClient, store: AttributeStore) {
    this._entityMetadata = entityMetadata
    this._store = store
    this._token = store.get(AuthConstants.RASA_AUTH_TOKEN)
    this._stats = Stats.init()
  }

  public get token(): string {
    return this._token;
  }

  public get person(): PersonInfo {
    return this._person;
  }
  public get activeCommunity(): UserCommunityInfo {
    return this._activeCommunity;
  }

  public get stats(): Stats {
    return this._stats;
  }

  public get announcements(): AnnouncementInfo[] {
    return this._announcements;
  }

  public switchCommunity(selectedCommunity) {
    selectedCommunity = isEmpty(selectedCommunity) || selectedCommunity === 'null' ||
    selectedCommunity === 'undefined' ? null : selectedCommunity
    const isSelectedCommunityEmpty = selectedCommunity ? false : true
    return Promise.all([
      new Dataset().loadCommunityDataset('communityUserDetail', selectedCommunity),
      isSelectedCommunityEmpty ? new Dataset().loadCommunityDataset('userNewsletters') : Promise.resolve([]),
    ])
    .then(([userCommunityDs, userNewslettersDs]) => {
      const userCommunity = userCommunityDs[0] || []
      const userNewslettersCount = userNewslettersDs[0] ? userNewslettersDs[0].length : 0

      if (userCommunity[0]) {
        this._activeCommunity = new UserCommunityInfo(userCommunity[0])
        this._person = new PersonInfo(this._activeCommunity.data)
        this._store.set(SELECTED_COMMUNITY, this._activeCommunity.communityId)
        return Promise.all([
          this.getUserAndAccountInfo(),
          this._entityMetadata.getEntityObject('communityInfo'),
          getCommunityNextIssue(this._activeCommunity.communityId),
          BillingUtils.getProductSubscription(this._activeCommunity.communityId),
          this.getCommunitySystems(selectedCommunity),
          this.getEmailDomains(selectedCommunity),
          this.getAnnouncements(),
        ])
        .then(([
          accountInfo,
          communityEntity,
          nextIssueResult,
          productSubscriptionResult,
          communitySystemsDS,
          emailDomainsDS,
          announcementsDS,
        ]) => {
          const communitySystems = communitySystemsDS[0]
          const announcements = announcementsDS[0]
          const emailDomains = emailDomainsDS[0]
          this._person.accountInfo = accountInfo
          this._person.billingInfo = productSubscriptionResult
          this._person.isInvalidDomain = emailDomains.filter((ed) => ed.status === DomainStatus.invalid && !isNil(ed.from_address)).length > 0
          this._person.connectedCommunitySystems = communitySystems.filter((s) => s.is_active === 1)

          this._activeCommunity.disconnectedCommunitySystems = communitySystems.filter((s) => s.status === DISCONNECTED)
          this._activeCommunity.failedCommunityIntegrations = communitySystems.filter(s => {
            const systemApiAttribute = JSON.parse(s.system_api_attribute)
            return (s.status !== DISCONNECTED && systemApiAttribute &&
              systemApiAttribute.consecutive_failed_count >= this._activeCommunity.data.integration_max_failed_count)
          })
          this._activeCommunity.communityInfo = communityEntity
          this._activeCommunity.nextIssue = nextIssueResult
          this._activeCommunity.billingInfo = productSubscriptionResult
          this._announcements = announcements
          return Promise.all([
            communityEntity.load(this._activeCommunity.communityId, this._activeCommunity.communityId),
          ])
        })
        .then(() => {
          if (isSelectedCommunityEmpty && userNewslettersCount > 1) {
            // user came from login and have multi newsletter access
            // wait for everything to be loaded and then send to newsletters page
            if (!this.isMobile()) {
              window.location.href = DashboardMenuOption.newsletters
            }
          }
          return Promise.resolve(this)
        })
      } else {
        return Promise.reject(NO_COMMUNITY)
      }
    }).catch((err) => {
        switch (err) {
          case NO_COMMUNITY:
            this.handleNoUserCommunity()
            break
          default:
            // eslint-disable-next-line no-console
            console.log(err)
            break
        }
    })
  }

  public init(forceReload?: boolean) {
    if (!this._token) {
      return Promise.reject('MissingToken')
    }
    return this.getUserCommunities(forceReload)
  }

  private getUserCommunities(forceReload) {
    // eslint-disable-next-line @typescript-eslint/no-misused-promises
    if (!User._getUserCommunitiesPromise || forceReload) {
      this.stats.start(StatsType.USER_INIT)
      const selectedCommunity = this._store.get(SELECTED_COMMUNITY)
      User._getUserCommunitiesPromise = this.switchCommunity(selectedCommunity)
      .then(() => {
        this.stats.stopAndLog(StatsType.USER_INIT)
        return Promise.resolve(this)
      })
    }
    return User._getUserCommunitiesPromise
  }

  private handleNoUserCommunity() {
    //TODO: create a new page with messaging advising users they don't have access to that community
    // Send users to the new page instead of logout
    Utils.logout()
  }

  private getUserAndAccountInfo(): Promise<AccountAndUserInfo> {
    return this._entityMetadata.getEntityObject('user')
    .then((entity) => {
        return Promise.all([
          entity.load(this._activeCommunity.communityId, this._person.userId),
        ])
        .then(() => {
          return {
            ...entity.data,
          }
        })
    })
  }

  private getCommunitySystems = (selectedCommunity) => {
    return new Dataset().loadCommunityDataset('communityIntegrations', selectedCommunity, [])
  }

  private getEmailDomains = (selectedCommunity) => {
    return new Dataset().loadCommunityDataset('emailDomains', selectedCommunity, [])
  }

  private getAnnouncements = () => {
    return new Dataset().loadGlobalDataset('announcements', [])
  }

  private isMobile(): boolean {
    return window.matchMedia('(max-width: 600px)').matches
  }

}
