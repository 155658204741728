import * as ReduxObservable from 'redux-observable'
import {catchError, map, tap} from 'rxjs/operators'
import * as Actions from './actions'
import * as Constants from './constants'

type SetEpic = <T extends string>(storageType: T) => ReduxObservable.Epic<Actions.SetAction, any, any>
const setEpic: SetEpic = (storageType) => (action$, _, {window}) =>
  action$
    .ofType(Constants.normalizeTypes(storageType, Constants.SET))
    .pipe(
      tap(({key, value}) => window[storageType].setItem(key, JSON.stringify(value))),
      map(Actions.createSetComplete(storageType)),
    )

type GetEpic = <T extends string>(storageType: T) => ReduxObservable.Epic<Actions.GetAction, any, any>
const getEpic: GetEpic = (storageType) => (action$, _, {window}) =>
  action$
    .ofType(Constants.normalizeTypes(storageType, Constants.GET))
    .pipe(
      map(({key}) => ({key, value: JSON.parse(window[storageType].getItem(key))})),
      map(Actions.createGetComplete(storageType)),
      // eslint-disable-next-line no-console
      catchError((error) => [console.error(error)])
    )

const setLocalStorage = setEpic<Constants.TLocalStorage>(Constants.LOCAL_STORAGE)
const getLocalStorage = getEpic<Constants.TLocalStorage>(Constants.LOCAL_STORAGE)
const setSessionStorage = setEpic<Constants.TSessionStorage>(Constants.SESSION_STORAGE)
const getSessionStorage = getEpic<Constants.TSessionStorage>(Constants.SESSION_STORAGE)

export const epic = ReduxObservable.combineEpics(
  getLocalStorage,
  getSessionStorage,
  setLocalStorage,
  setSessionStorage,
)
