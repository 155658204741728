import isEmpty from 'lodash/isEmpty'
import * as React from 'react'
import './_style.scss'

export interface FileSelectorProps {
  fileType?: string,
  onSelect: any,
  sampleFileLocation?: string,
  sampleInterestsFileLocation?: string,
  showInterestsFile?: boolean,
}

interface FileSelectorState {
  selectedFileName: string,
}
export class FileSelector extends React.Component<FileSelectorProps, FileSelectorState> {

  constructor(props: FileSelectorProps) {
    super(props)
    this.state = {
      selectedFileName: null,
    }
  }

  public render() {
    return <div className="file-upload">
      <div className="file-sample">
        {!isEmpty(this.props.sampleFileLocation) &&
        <a target="_blank"
          href={this.props.sampleFileLocation}
          rel="noopener">Download sample file</a>}
        {!isEmpty(this.props.sampleInterestsFileLocation) && this.props.showInterestsFile ?
            <a target="_blank"
               href={this.props.sampleInterestsFileLocation}
               rel="noopener">Download Interests sample file</a> : null}
      </div>
      <div>

        <input type="file" accept={`.${this.fileType()}`}
               name="file" id="file" onChange={(e) => this.selectFile(e)}/>
        <div className="input-flex">
          <div className="fake-input">
            {this.state.selectedFileName}
          </div>
          <label className="cursor" htmlFor="file">
            <div className="browse-button">
              <span className="actual-button">
                Browse
              </span>
            </div>
          </label>
        </div>
        <p>Acceptable file types: {this.fileType().toUpperCase()} files.</p>
      </div>
    </div>

  }

  private fileType(): string {
    return this.props.fileType || 'csv'
  }

  private selectFile(e) {
    if (e.target.files[0]) {
      this.setState({
        selectedFileName: e.target.files[0].name,
      })
      if (this.props.onSelect ) {
        this.props.onSelect(e.target.files[0])
      }
    }
  }
}
