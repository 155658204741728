import * as React from 'react'

export interface ExpireProps {
  delay: number,
}

export interface ExpireState {
  visible: boolean
}

export class Expire extends React.Component<ExpireProps, ExpireState> {
  private _timer: any = null
  constructor(props: ExpireProps) {
    super(props);
    this.state = {
      visible: true,
    }
  }

  public componentWillReceiveProps(nextProps) {
    // reset the timer if children are changed
    if (nextProps.children !== this.props.children) {
      this.setTimer();
      this.setState({visible: true});
    }
  }

  public componentDidMount() {
    this.setTimer()
  }

  public componentWillUnmount() {
    clearTimeout(this._timer)
  }

  public render() {
    return this.state.visible
      ? <div>{this.props.children}</div>
      : <span />
  }

  private setTimer() {
    // clear any existing timer
    if (this._timer !== null) {
      clearTimeout(this._timer)
    }

    if (this.props.delay >= 0) {
    // hide after `delay` milliseconds
      this._timer = setTimeout(function() {
        this.setState({
          visible: false,
        });
        this._timer = null;
      }.bind(this), this.props.delay)
    }
  }

}
