import { Dataset, DatasetParam } from 'generic/dataset'
import * as GenerativeAI from 'shared_server_client/types/generative_ai'

export interface DomainTopics {
  categories: GenerativeAI.Keyword[],
  error? : string,
  topics: GenerativeAI.Keyword[],
  summary?: string,
}

export interface Query {
  engine?: string,
  model?: string,
  includeSummary?: number,
  isTest?: number,
  parent?: string,
  rank?: number,
  source?: string,
  type?: string,
  version?: string,
  url: string,
}

const addParam = (params: DatasetParam[], key: string, value: number|string) => {
  if (value) {
    params.push({
      param: key,
      value,
    })
  }
  return params
}

export const getDomainTopics = (query: Query): Promise<DomainTopics> => {
  const params: DatasetParam[] = [
    {
      param: 'url',
      value: query.url,
    },
  ]
  addParam(params, 'engine', query.engine)
  addParam(params, 'version', query.version)
  addParam(params, 'parent', query.parent)
  addParam(params, 'source', query.source)
  addParam(params, 'type', query.type)
  addParam(params, 'includeSummary', query.includeSummary)
  addParam(params, 'rank', query.rank)
  return new Dataset().loadCommunityDataset('discoverDomainTopics', 'rasa', params)
}
