import classnames from 'classnames'
import * as React from 'react'
import {connect} from 'react-redux'
import {CSSTransition} from 'react-transition-group'

export const createNotificationComponent = (prefix) => connect(
  ({notifications}: any, ownProps) => ({notifications, ...ownProps}),
)(({notifications, delay, children, className, onClick, ...rest}: any) =>
<CSSTransition
  appear={true}
  in={true}
  classNames="slide"
  timeout={1000}>
    <div onClick={onClick}
      className={classnames('notification', className, {'is-open': (notifications[prefix] || {}).open})}>
      <div className="content">
        {children}
      </div>
      <div className="dismiss">
        <span>x</span>
      </div>
    </div>
  </CSSTransition>)
