import * as Redux from 'redux'
import * as Constants from './constants'

export type NotificationActionCreator = () => Redux.Action
export type NotificationActionWithContextCreator = (context: any) => Redux.Action

export const close = (prefix: string): NotificationActionCreator =>
  () => ({type: Constants.closeConstant(prefix)})
export const open = (prefix: string): NotificationActionWithContextCreator =>
  (context: any = {}) => ({type: Constants.openConstant(prefix), context})
export const toggle = (prefix: string): NotificationActionCreator =>
  () => ({type: Constants.toggleConstant(prefix)})

export interface NotificationActions {
  close: NotificationActionCreator,
  open: NotificationActionWithContextCreator,
  toggle: NotificationActionCreator,
}

export const createActions = (prefix: string): NotificationActions => ({
  close: close(prefix),
  open: open(prefix),
  toggle: toggle(prefix),
})
