import { DashboardMenuOption } from 'components/dashboard-menu/constants'

declare const RASA_DASHBOARD_BASE_URL: string

export interface KeyedDescription {
  description: string,
  key: string,
}
export const DESIGN_MODE: KeyedDescription = {
  description: 'The articles populated are for example purposes \
                only and do not reflect what will be sent in your upcoming newsletter.',
  key: 'design',
}

export const PREVIEW_MODE: KeyedDescription = {
  description: `<b>Please note:</b> this preview does not take into account your max per source setting or cut-off time for content. \
  Any articles displayed that are not visible in your <a style="color: #72cac2" href=${RASA_DASHBOARD_BASE_URL}${DashboardMenuOption.content}>Upcoming Content</a> will not be included in your actual newsletter.`,
  key: 'preview',
}
