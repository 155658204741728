import * as Redux from 'redux'
import * as Constants from './constants'
import * as Types from './types'

export interface ModalAction extends Redux.Action {
  data?: any,
  key: string,
  maxTimesToShow?: number,
}

export type Open = (key: string, data: any, maxTimesToShow?: number) => ModalAction
export type Close = (key: string, data?: Types.Fields) => ModalAction
export type Reset = (key: string) => ModalAction
export type Save = (key: string, data: Types.Fields) => ModalAction
export type Set = (key: string, data: Types.Fields) => ModalAction

export interface UpdateFieldAction extends ModalAction { field: Types.ModalField }
export type Update = (key: string, field: Types.ModalField) => UpdateFieldAction

export const saveModal = (prefix: string): Save =>
  (key: string, data: Types.Fields) =>
    ({key, type: Constants.saveModalConstant(prefix, key), data})
export const openModal = (prefix: string): Open =>
  (key: string, data: any, maxTimesToShow?: number) =>
    ({data, key, type: Constants.openModalConstant(prefix, key), maxTimesToShow})
export const closeModal = (prefix: string): Close =>
  (key: string, data?: Types.Fields) =>
    ({key, type: Constants.closeModalConstant(prefix, key), data})
export const updateModal = (prefix: string): Update =>
  (key: string, field: Types.ModalField) =>
    ({key, type: Constants.updateModalConstant(prefix, key), field})
export const setSessionModal = (prefix: string): Set =>
  (key: string, data: Types.Fields) =>
    ({key, type: Constants.setSessionModalConstant(prefix, key), data})
export const resetModal = (prefix: string): Reset =>
  (key: string) =>
    ({key, type: Constants.resetModalConstant(prefix, key)})

export interface ModalActionList {
  closeModal: Close,
  openModal: Open,
  setSessionModal: Set,
  saveModal: Save,
  updateModal: Update
  resetModal: Reset
}

export const createActionList = (scope: string): ModalActionList => {
  return {
    closeModal: closeModal(scope),
    openModal: openModal(scope),
    setSessionModal: setSessionModal(scope),
    saveModal: saveModal(scope),
    updateModal: updateModal(scope),
    resetModal: resetModal(scope),
  }
}
