import * as Constants from './constants'

export const createReducer = ({prefix, initialState = false}) =>
  (state, action) => {
    switch (action.type) {
      case Constants.toggleConstant(prefix):
        return {
          ...state,
          [prefix]: {
            open: !state,
          },
        }
      case Constants.closeConstant(prefix):
        return {
          ...state,
          [prefix]: {
            open: false,
            context: {},
          },
        }
      case Constants.openConstant(prefix):
        return {
          ...state,
          [prefix]: {
            open: true,
            context: {
              ...action.context,
            },
          },
        }
      default:
       return  state || {}
    }
  }
