import * as Types from './types'

const modalConstant = (prefix: string, key: string, suffix: string) => `MODAL_${prefix}_${key}_${suffix}`

export const closeModalConstant = (prefix: string, key: string) => modalConstant(prefix, key, 'CLOSE')
export const resetModalConstant = (prefix: string, key: string) => modalConstant(prefix, key, 'RESET')
export const openModalConstant = (prefix: string, key: string) => modalConstant(prefix, key, 'OPEN')
export const setSessionModalConstant = (prefix: string, key: string) => modalConstant(prefix, key, 'SET')
export const saveModalConstant = (prefix: string, key: string) => modalConstant(prefix, key, 'SUBMIT')
export const updateModalConstant = (prefix: string, key: string) => modalConstant(prefix, key, 'UPDATE')

export const emptyState: Types.ModalStateData = {
  data: {},
  isOpen: false,
  isSaved: false,
  maxTimesToShow: 0,
  numTimesShowed: 0,
}

export enum ModalSize {
  Small = 'small',
  Medium = 'medium',
  Large = 'large',
}
