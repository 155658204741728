
export abstract class MetadataItem {
  public readonly name: string
  constructor(name: string) {
    this.name = name
  }
}

export abstract class MetadataBase<T extends MetadataItem> {

  private readonly _metadata: T[] = []

  public register(info: T) {
    this._metadata[this._metadata.length] = info;
  }

  public find(name: string) {
    return this._metadata.find((item) => item.name === name)
  }

  public allMetadata(): T[] {
    return this._metadata;
  }
}
