import {snakeCase} from 'lodash'

export type TLocalStorage = 'localStorage'
export type TSessionStorage = 'sessionStorage'
export type StorageType = TLocalStorage | TSessionStorage
export const LOCAL_STORAGE = 'localStorage'
export const SESSION_STORAGE = 'sessionStorage'
export const SET = 'SET'
export const GET = 'GET'
export const SET_COMPLETE = 'SET_COMPLETE'
export const GET_COMPLETE = 'GET_COMPLETE'
export const normalizeTypes = (storageType: string, type: string) => `${snakeCase(storageType).toUpperCase()}_${type}`
