import {isEmpty} from 'lodash'

export enum SharedKeys {
  activeCommunity = 'activeCommunity',
  announcements = 'announcements',
  config= 'config',
  hash= 'hash',
  emailLayoutId = 'emailLayoutId',
  emailTemplate = 'emailTemplate',
  params = 'params',
  person = 'person',
  role = 'role',
}

export class SharedStore {
  public static instance(context) {
    if (!SharedStore.classInstance) {
      SharedStore.classInstance = new SharedStore(context)
    }
    return SharedStore.classInstance
  }
  private static classInstance: SharedStore

  private activeCommunity
  private context
  private person
  private state

  private constructor(context) {
    this.context = context
    this.state = this.context.store.getState()
  }

  public getValue(key: SharedKeys, force = false) {
    return this.context.user.init(force).then(({person, activeCommunity}) => {
      this.state = this.context.store.getState()
      this.person = person
      this.activeCommunity = activeCommunity

      if (key === SharedKeys.activeCommunity) { return this.activeCommunity }
      if (key === SharedKeys.person) { return this.person }

      if (key === SharedKeys.emailTemplate) { return this.emailTemplateValue() }
      if (key === SharedKeys.hash) { return this.hashValue() }
      if (key === SharedKeys.params) { return this.paramsValue() }
      if (key === SharedKeys.role) { return this.roleValue() }
      if (key === SharedKeys.config) { return this.configValue() }

      return null
    })
  }
  public refresh() {
    return this.context.user.init(true)
  }
  private configValue() {
    if (this.state && this.state.config) {
      return this.state.config
    } else {
      return {}
    }
  }

  private emailTemplateValue() {
    if (this.state.email_layout && this.state.email_layout.data && this.state.email_layout.data.email_template) {
      return this.state.email_layout.data.email_template
    } else if (this.activeCommunity.communityInfo && this.activeCommunity.communityInfo.data
        && this.activeCommunity.communityInfo.data.email_layouts) {
      const emailLayout = this.activeCommunity.communityInfo.data.email_layouts
        .filter((el) => el.is_active && isEmpty(el.community_partner_code))[0]
      return emailLayout ? emailLayout.value : ''
    } else {
      return ''
    }
  }

  private hashValue() {
    if (this.state.app && this.state.app.hash) {
      return this.state.app.hash
    } else {
      return ''
    }
  }

  private paramsValue() {
    if (this.state.app && this.state.app.params) {
      return this.state.app.params
    } else {
      return {}
    }
  }

  private roleValue() {
    return this.activeCommunity ? this.activeCommunity.role : null
  }
}
