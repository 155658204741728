import { differenceInDays, parseISO } from 'date-fns'
import * as Constants from './constants'
import { CurrentPlan, isFreePlan } from './types/billing_plan'
import { UsageStats } from './types/usage_stats'

export const isApproachingLimit = (
  currentPlan: CurrentPlan,
  usageStats: UsageStats,
  scheduleFrequency: string,
): boolean => {
  if (isFreePlan(currentPlan) && notOverLimit(usageStats) && projectedToGoOver(usageStats)) {
    switch (scheduleFrequency.toLowerCase()) {
      case Constants.ScheduleFrequency.weekly:
        return (usageStats.numActualMessages / usageStats.currentPlanIncludedMessages) > .75
      case Constants.ScheduleFrequency.biweekly:
        return (usageStats.numActualMessages / usageStats.currentPlanIncludedMessages) > .5
      case Constants.ScheduleFrequency.daily:
        return (usageStats.numActualMessages / usageStats.currentPlanIncludedMessages) > .9
    }
  } else {
    return false
  }
}

export const wontSend = (
  currentPlan: CurrentPlan,
  usageStats: UsageStats,
  lastSendStatus: string,
  cutoff: string,
): boolean => {
  if (isFreePlan(currentPlan) && !usageStats.hasUnlimitedMessages &&
      (nextSendIsSoon(cutoff) || lastSendWasCapped(lastSendStatus)) &&
      (alreadyOverLimit(usageStats) || nextSendWillGoOverLimit(usageStats))
  ) {
    return true
  } else {
    return false
  }
}

const nextSendIsSoon = (nextSendCutoff) => differenceInDays(parseISO(nextSendCutoff), new Date()) < 1
const notOverLimit = (s) => s.numActualMessages < s.currentPlanIncludedMessages
const alreadyOverLimit = (s) =>  s.numActualMessages > s.currentPlanIncludedMessages
const nextSendWillGoOverLimit = (s) => s.subscriberCount + s.numActualMessages > s.currentPlanIncludedMessages
const projectedToGoOver = (s) => s.projectedOveragePercent > 0
const lastSendWasCapped = (lastSendStatus) => lastSendStatus === Constants.CAPPED
