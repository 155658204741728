import {reducer as router} from 'router'
import {reducer as app} from './app/reducer'
import {reducer as auth} from './auth/reducer'
import {reducer as appCues} from './components/app-cues/reducer'
import {reducer as banner} from './components/banner/reducer'
import {reducer as contentFilters} from './components/content-pool/filters/reducer'
import {reducer as flash} from './components/flash/reducer'
import {reducer as notifications} from './components/notifications/reducer'
import {reducer as twoFactorAuth} from './pages/2fa-auth/reducer'
import {reducer as forgotPassword} from './pages/forgot-password/reducer'
import {reducer as login} from './pages/login/reducer'
import {reducer as resetPassword} from './pages/reset-password/reducer'
import {reducer as signup} from './pages/signup/reducer'

export const rootReducer = {
  app,
  appCues,
  auth,
  banner,
  config: (s = {}) => s,
  community_filters: contentFilters,
  flash,
  forgotPassword,
  login,
  notifications,
  resetPassword,
  router,
  signup,
  twoFactorAuth,
}
