export enum Roles {
  admin = 'admin',
  community_admin = 'community_admin',
  content = 'content',
  member = 'member',
  super_admin = 'super-admin',
}

export const DATETIME_FORMAT = 'MMM dd, yyyy HH:mm'
export const DATEPICKER_DATE_FORMAT = 'yyyy-MM-dd'
export const DATEPICKER_DATETIME_FORMAT = 'yyyy-MM-dd HH:mm'
export const MYSQL_MEDIUM_TEXT_LENGTH = 16777215
export const ACTIVE_COMMUNITY_NOT_FOUND_ERROR = 'Something went wrong. Active community doesn\'t found'
export const RETRY_LIMIT = 2
export const RETRY_WAIT_TIME = 5000
