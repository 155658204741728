import { ajax } from 'rxjs/ajax'
import uuid from 'uuidv4'
import { getPublicServerUrl } from './constants'

export const isLocal = (): boolean => {
  return window.location.href.startsWith('http://localhost:')
}

export const generateGuid = () => uuid()

export const writeLog = (payload) => {
  if ( process.env.SKIP_LOG ) {
    return
  } else {
    try {
      const url: string = getPublicServerUrl() + '/monitoring/dashboard/log'
      const headers = {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
        'rxjs-custom-header': 'Rxjs',
      }
      return ajax({
        body: payload,
        headers,
        method: 'POST',
        url,
      }).toPromise()
    } catch (error) {
      // do nothing. No need to print. Error would in the network
    }
  }
}
