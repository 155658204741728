import { Dataset } from 'generic/dataset'

export interface WhitelistDomain {
  alternate_domain?: string,
  distribution_domain?: string
  distribution_id?: string,
}

export interface WhitelistDomains {
  [key: string]: WhitelistDomain
}

export const newsletterWhitelistDomains = (
    accountGuid: string,
    communityIdentifier: string,
    status: string = 'valid',
  ): Promise<WhitelistDomains> => {
  return new Dataset().loadGlobalDataset('whitelistDomains', [
    { param: 'accountGuid', value: accountGuid },
    { param: 'status', value: status },
    { param: 'communityIdentifier', value: communityIdentifier },

  ]).then((whitelistDomainsResult) => {
    const whitelistDomains = whitelistDomainsResult[0]
    const newsletterDomains: WhitelistDomains = {}

    whitelistDomains.forEach((curRecord) => {
      newsletterDomains[curRecord.website_name] = {
        alternate_domain: curRecord.alternate_domain,
        distribution_domain: curRecord.website_distribution_domain,
        distribution_id: curRecord.website_distribution_id,
      }
    })
    return newsletterDomains
  })
}
