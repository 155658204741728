import {combineEpics} from 'redux-observable'
import {epic as request} from 'request'
import {epic as router} from 'router'
import {epic as app} from './app/epic'
import {epic as auth} from './auth/epic'
import {epic as appCues} from './components/app-cues/epic'
import {epic as filters} from './components/content-pool/filters/epic'
import {epic as addTwitter} from './components/content-pool/twitter/epic'
import {epic as generateTextEpic} from './components/generate-text/epic'
import {epic as notifications} from './components/notifications/epic'
import {epic as billingEpic} from './components/settings-editor/account/billing/epic'
import {epic as genericEpic} from './generic/epic'
import {epic as googleAnalytics} from './google-analytics/epic'
import {epic as twoFactorAuth} from './pages/2fa-auth/epic'
import {epic as analytics} from './pages/analytics/epic'
import {epic as forgotPassword} from './pages/forgot-password/epic'
import {epic as integrationsCallback} from './pages/integrations-callback/epic'
import {epic as login} from './pages/login/epic'
import {epic as logout} from './pages/logout/epic'
import {epic as resetPassword} from './pages/reset-password/epic'
import {epic as signup} from './pages/signup/epic'
import {epic as subscribers} from './pages/subscribers/epic'
import {epic as storage} from './storage/epic'
import {epic as user} from './user/epic'
import {epic as generateImageEpic} from './components/schedule-editor/scheduled-content/modals/epic'

export const rootEpic = combineEpics(
  analytics,
  app,
  appCues,
  auth,
  addTwitter,
  billingEpic,
  filters,
  forgotPassword,
  generateTextEpic,
  generateImageEpic,
  genericEpic,
  googleAnalytics,
  integrationsCallback,
  login,
  logout,
  notifications,
  request,
  resetPassword,
  router,
  signup,
  storage,
  subscribers,
  twoFactorAuth,
  user,
)
