import * as Redux from 'redux'
export * from './constants'
export * from './types'

// Configure the store
let rasaStore: any
let _tempData: any = {}
let _rootReducerData: any

const createReducer = (asyncReducers: any) =>
  Redux.combineReducers({
    ..._rootReducerData,
    ...asyncReducers,
  })

export const injectReducer = (key: string, reducer: any) =>
  rasaStore
    ?  rasaStore.injectReducer(key, reducer)
    : _tempData[key] = reducer

export const configureStore = (rootReducerData: any, data: any, moreData: any) => {
  _rootReducerData = rootReducerData

  if (Object.keys(_tempData).length > 0) {
    const newReducers = Redux.combineReducers({
      ...rootReducerData,
      ..._tempData,
    })
    rasaStore = Redux.createStore(newReducers, data, moreData);

    // Add a dictionary to keep track of the registered async reducers
    // grab the temp data and then wipe it out - if people called injectReducer
    //before store was created this is used
    rasaStore.asyncReducers = {..._tempData}
    _tempData = {}
  } else {
    rasaStore = Redux.createStore(Redux.combineReducers(rootReducerData), data, moreData)
    rasaStore.asyncReducers = {} // blank initially
  }

  // Create an inject reducer function
  // This function adds the async reducer, and creates a new combined reducer
  rasaStore.injectReducer = (key: string, asyncReducer: any) => {
    rasaStore.asyncReducers[key] = asyncReducer
    rasaStore.replaceReducer(createReducer(rasaStore.asyncReducers))
  }

  // Return the modified store
  return rasaStore
}
