import {snakeCase} from 'lodash'
import * as Redux from 'redux'
import * as Constants from './constants'

export interface SetAction extends Redux.Action {key: string, value: any}
export interface GetAction extends Redux.Action {key: string}
export type Setter = <T extends string>(storageType: T) => Redux.ActionCreator<SetAction>
export type Getter = <T extends string>(storageType: T) => Redux.ActionCreator<GetAction>
const setter: Setter = (storageType) => ({key, value}) =>
  ({type: Constants.normalizeTypes(storageType, Constants.SET), key, value})
const getter: Getter = (storageType) => ({key}) =>
  ({type: Constants.normalizeTypes(storageType, Constants.GET), key})

export const setLocalStorage = setter<Constants.TLocalStorage>(Constants.LOCAL_STORAGE)
export const getLocalStorage = getter<Constants.TLocalStorage>(Constants.LOCAL_STORAGE)
export const setSessionStorage = setter<Constants.TSessionStorage>(Constants.SESSION_STORAGE)
export const getSessionStorage = getter<Constants.TSessionStorage>(Constants.SESSION_STORAGE)

export interface GetCompleteAction extends Redux.Action {key: string, value: any}
export type CreateGetComplete = (storageType: any) => Redux.ActionCreator<GetCompleteAction>
export const createGetComplete: CreateGetComplete = (storageType) => (item) =>
  ({type: `${snakeCase(storageType).toUpperCase()}_${Constants.GET_COMPLETE}`, ...item})
export type SetCompleteAction = Redux.Action
export type CreateSetComplete = (storageType: any) => Redux.ActionCreator<SetCompleteAction>
export const createSetComplete: CreateSetComplete = (storageType) => ({key, value}) =>
  ({type: `${snakeCase(storageType).toUpperCase()}_${Constants.SET_COMPLETE}`, key, value})
