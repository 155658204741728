// THIS FILE IS A BASE IMPLEMENTATION - SUB-CLASSES ARE DEFINED FOR CLIENT
// AND SERVER THAT USE AJAX and DB RESPECTIVELY
//
// File that has class for capturing entity meta-data
// ths entity metadata will be shared between the server and the client
import * as MetadataBase from './metadataBase'

export enum EntityFieldType {
  string = 'string',
  number = 'number',
  datetime = 'datetime',
  varchar = 'varchar',
}

export class EntityFieldMetadataItem extends MetadataBase.MetadataItem {
  public readonly type: EntityFieldType
  public readonly spName: string
  public readonly create: boolean
  public readonly update: boolean
  public readonly isId: boolean

  constructor(name: string, type: EntityFieldType, spName: string, create: boolean, update: boolean, isId: boolean) {
    super(name)
    this.type = type
    this.spName = spName
    this.create = create
    this.update = update
    this.isId = isId
  }
}

export class EntityMetadataItem {
  public baseTable: string
  public entityServerClass: string;
  public entityClientClass: string;
  public name: string;
  public spCreate: string;
  public spDelete: string;
  public spGet: string;
  public spUpdate: string;
  public tableNames: string[];
  public fields: EntityFieldMetadataItem[];

  constructor(
    name: string,
    entityServerClass: string,
    entityClientClass: string,
    spGet?: string,
    spCreate?: string,
    spUpdate?: string,
    spDelete?: string,
    tableNames?: string[],
    fields?: EntityFieldMetadataItem[],
  ) {
    this.name = name
    this.entityServerClass = entityServerClass
    this.entityClientClass = entityClientClass
    this.spGet = spGet
    this.spCreate = spCreate
    this.spUpdate = spUpdate
    this.spDelete = spDelete
    this.tableNames = tableNames
    this.fields = fields
  }
}

export abstract class EntityMetadataBase
  extends MetadataBase.MetadataBase<EntityMetadataItem> {

  public abstract getEntityObject(entityName: string): Promise<any>;

  public mapDBType(dbType: string): EntityFieldType {
    return mapDBType(dbType);
  }

  // These mapFields and mapSubTables functions exist because the array.map() results
  // in items being added to the new array even for situations where they shouldn't
  // AND it transforms them from the DB stuff into the local type system.
  protected _mapFields(allFields: any[], entityId: number): EntityFieldMetadataItem[] {
    return allFields.filter((field: any) => field.entityId === entityId)
                    .map((field: any) => {
                      return new EntityFieldMetadataItem(
                        field.name,
                        this.mapDBType(field.type),
                        field.spName,
                        field.create,
                        field.update,
                        field.isId,
                      )
                    })
  }

  protected _mapSubTables(allSubTables: any[], entityId: number): string[] {
    return allSubTables.filter((table: any) => table.entityId === entityId)
                       .map((table: any) => table.tableName)
  }
}

export function mapDBType(dbType: string): EntityFieldType {
  switch (dbType.trim().toLowerCase()) {
    case 'int':
    case 'tinyint':
    case 'smallint':
    case 'bigint':
    case 'decimal':
    case 'float':
      return EntityFieldType.number
    case 'datetime':
    case 'time':
    case 'date':
    case 'datestamp':
      return EntityFieldType.datetime
    default:
      return EntityFieldType.string
  }
}
