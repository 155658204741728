export interface ActualIssue {
  expected: number,
  identifier: string,
  num_sent: number,
  send_at: any,
}

export interface SubscriberCount {
  contact_count: number,
  identifier: string,
  subscriber_count: number,
}

export interface UsageStatsDataset {
  actualIssues: ActualIssue[],
  subscriberCounts: SubscriberCount[],
  upcomingIssues?: any[],
}

export interface UsageStatsDates {
  currentDate?: string,
  lastBillingDate: string,
  nextBillingDate: string,
}

export interface TierPrice {
  code: string,
  cost: number,
  count: number,
  id: number,
  maximum: number,
}

export interface UsageStats {
  boostSourcesCount: number,
  canAddNewsletters: boolean,
  cappedProjectedPercent: number,
  contactCount: number,
  currentPlanIncludedContacts: number,
  currentPlanIncludedMessages: number,
  currentPlanMaxContacts: number,
  hasUnlimitedContacts: boolean,
  hasUnlimitedMessages: boolean,
  numActualIssues: number,
  numActualMessages: number,
  numNewsletters: number,
  numProjectedIssues: number,
  numProjectedMessages: number,
  numProjectedTotalMessages: number,
  projectedContactPricing: TierPrice,
  projectedOveragePercent: number,
  reportingLookbackMonths: number,
  sourcesCount: number,
  subscriberCount: number,
  usagePercent: number,
}

export const enterpriseUsage: UsageStats = {
  boostSourcesCount: 0,
  canAddNewsletters: true,
  cappedProjectedPercent: 0,
  contactCount: 0,
  currentPlanIncludedContacts: -1,
  currentPlanIncludedMessages: -1,
  currentPlanMaxContacts: -1,
  hasUnlimitedContacts: true,
  hasUnlimitedMessages: true,
  numActualIssues: 0,
  numActualMessages: 0,
  numNewsletters: 0,
  numProjectedIssues: 0,
  numProjectedMessages: 0,
  numProjectedTotalMessages: 0,
  projectedContactPricing: {
    code: '',
    cost: 0,
    count: 0,
    id: 0,
    maximum: 0,
  },
  projectedOveragePercent: 0,
  reportingLookbackMonths: 0,
  sourcesCount: 0,
  subscriberCount: 0,
  usagePercent: 0,
}
