import * as Auth from 'auth'
import * as ReduxObservable from 'redux-observable'
import {map, switchMap, tap} from 'rxjs/operators'
import * as Constants from './constants'

declare const _rasa_init_page

const userInit = (action$, state$, {user}) =>
  action$.ofType(Auth.VALID_TOKEN)
    .pipe(
      switchMap(() => user.init()),
      map((u) => ({type: Constants.USER_INIT, user: u})),
    )

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const sendLoginCookies = (action$, state$, {window}) =>
  action$.ofType(Constants.USER_INIT)
    .pipe(
      tap(({ user }) => {
        if (user && user.person) {
          return sendRasaCookies(user.person.email)
        }
      }),
      map(() => ({type: Constants.SENT_RASA_LOGIN_COOKIES})),
    )

export const sendRasaCookies = (email) => {
  const otherValues = [{name: 'application', value: 'dashboard'}, {name: 'login_email', value: email}]
  try {
    _rasa_init_page(otherValues)
  } catch (error) {
    // Do nothing - just ensure that we don't barf by failing to load the JS file
  }
}

export const epic = ReduxObservable.combineEpics(userInit, sendLoginCookies)
