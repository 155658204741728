import { Dataset } from 'generic/dataset'
import { UpcomingIssue } from 'shared_server_client/types/schedule'

export const getCommunityUpcomingIssues = (communityId): Promise<UpcomingIssue[]> => {
  return new Dataset().loadCommunityDataset('communityUpcomingIssues', communityId)
  .then((upcomingIssueResponse: any) => {
    if (upcomingIssueResponse && upcomingIssueResponse.length) {
      return upcomingIssueResponse[0]
    }
    return []
  })
}

export const getCommunityNextIssue = (communityId): Promise<UpcomingIssue> => {
  return getCommunityUpcomingIssues(communityId)
          .then((response: UpcomingIssue[]) => response.find((issue: UpcomingIssue) => issue.is_active &&
            issue.status !== 'Canceled'))
}

export const isInCutoffWindow = (issue: UpcomingIssue, currentTime: Date = null): boolean => {
  if (issue) {
    const now = currentTime || new Date()
    return now >= new Date(issue.cutoff) && now <= new Date(issue.production_cutoff)
  } else {
    return false
  }
}

export const isInGenerationWindow = (issue: UpcomingIssue, currentTime: Date = null): boolean => {
  if (issue) {
    const now = currentTime || new Date()
    const cutoffEnd = new Date(issue.production_cutoff)
    const sendTime = new Date(issue.date)
    return cutoffEnd <= now && now < sendTime
  } else {
    return false
  }
}

export const isBeforeCutoff = (issue: UpcomingIssue, currentTime: Date = null): boolean => {
  if (issue) {
    const now = currentTime || new Date()
    const cutoffStart = new Date(issue.cutoff)
    return now < cutoffStart
  } else {
    return false
  }
}
